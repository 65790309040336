import { appConfig } from '@/config/appConfig';
import { createHttp, createMJHttp, createMJHttp2 } from '@/api/HttpHelper';

const axios = createHttp(appConfig.base2GUrl);
const MJAxios = createMJHttp(appConfig.MJBaseUrl);
const MJAxios2 = createMJHttp2(appConfig.MJBaseUrl2);
const SystemAxios = createMJHttp(appConfig.SystemBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);
const YWAxios = createMJHttp(appConfig.YWBaseUrl);

// const post = (url, data, options) => axios.post(url, data, {
//    headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
//    ...options
// });

// 运维-大屏设置-项目信息
export const getProject = (params) => {
   return YWAxios.axios({
      url: 'basic-system/bigScreenData/getProject',
      method: 'get',
      loading: true,
      params
   });
};

// ---------------------------------汇总大屏--------------------------------------------------------------------------

//获取设备在线数据
export const getDeviceOnlineView = (params) => {
   return statisticsAxios.axios({
      url: '/largeScreen/getDeviceOnlineView',
      method: 'get',
      params
   });
};

//获取服务在线数据
// export const getServiceOnlineView = (params) => {
//    return statisticsAxios.axios({
//       url: '/largeScreen/getServiceOnlineView',
//       method: 'get',
//       params
//    });
// };

//获取服务在线数据
export const getServiceOnlineView = (params) => {
   return SystemAxios.axios({
      url: '/ins-claims/insClaimsCase/getServiceOnlineView',
      method: 'post',
      params
   });
};

//获取机手在线数据
export const getDriverOnlineView = (params) => {
   return statisticsAxios.axios({
      url: '/largeScreen/getDriverOnlineView',
      method: 'get',
      params
   });
};

//获取保单在线数据
// export const getPolicyOnlineView = (params) => {
//    return statisticsAxios.axios({
//       url: '/largeScreen/getPolicyOnlineView',
//       method: 'get',
//       params
//    });
// };

//获取保单在线数据 新
export const getPolicyOnlineView = (params) => {
   return SystemAxios.axios({
      url: '/ins-policy/policyArchive/getPolicyOnlineView',
      method: 'get',
      params
   });
};

//获取风控在线数据
export const getRiskControlOnlineView = (params) => {
   return statisticsAxios.axios({
      url: '/largeScreen/getRiskControlOnlineView',
      method: 'get',
      params
   });
};

//获取用户在线数据
export const getUserOnlineView = (params) => {
   return statisticsAxios.axios({
      url: '/largeScreen/getUserOnlineView',
      method: 'get',
      params
   });
};

// ---------------------------------设备大屏--------------------------------------------------------------------------
//获取设备在线大屏全数据
export const getAllDataOnPre = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/deviceOnline/getAllDataOnPre',
      method: 'get',
      params
   });
};

//获取各区域分布机械数量-地图下钻
export const getAreaVehicleCount = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/deviceOnline/getAreaVehicleCount',
      method: 'get',
      params
   });
};

// -----------------------------------------机械大屏----------------------------------------------------------------------------------

//获取机械大屏数据汇总-数量
export const getTerminalMonitor = (params) => {
   return statisticsAxios.axios({
      url: '/deviceMonitor/getTerminalMonitor',
      method: 'get',
      params
   });
};

//获取机械大屏数据汇总
export const largeScreenDeviceMonitor = (params) => {
   return statisticsAxios.axios({
      url: '/deviceMonitor/largeScreenDeviceMonitor',
      method: 'get',
      params
   });
};

//获取机械监控居中区域信息
export const MonitorPositionInfo = (params) => {
   return statisticsAxios.axios({
      url: '/deviceMonitor/monitorPositionInfo',
      method: 'get',
      params
   });
};

// -----------------------------------保单在线--------------------------------------------------------

//获取保单在线-机械统计
export const getProjectDetails = (params) => {
   return SystemAxios.axios({
      url: '/ins-policy/largeScreen/getProjectDetails',
      method: 'get',
      params
   });
};

//获取保单在线-服务保司统计
export const getCompanyNameDetails = (params) => {
   return SystemAxios.axios({
      url: '/ins-policy/largeScreen/getCompanyNameDetails',
      method: 'get',
      params
   });
};

//获取保单在线-保单统计
export const getPolicyCountDetails = (params) => {
   return SystemAxios.axios({
      url: '/ins-policy/largeScreen/getPolicyCountDetails',
      method: 'get',
      params
   });
};

// -----------------------------------服务在线--------------------------------------------------------

//获取服务在线-机械统计
export const getCaseProjectDetails = (params) => {
   return SystemAxios.axios({
      url: '/ins-claims/largeScreenClaim/getCaseProjectDetails',
      method: 'get',
      params
   });
};

// 获取风险减量数据
export const getRiskReductionDetails = (params) => {
   return SystemAxios.axios({
      url: '/ins-claims/largeScreenClaim/getRiskReductionDetails',
      method: 'get',
      params
   });
};

// 获取地图数据
export const getMapData = (params) => {
   return SystemAxios.axios({
      url: '/ins-claims/largeScreenClaim/getMapData',
      method: 'get',
      params
   });
};

// 获取赔付案件类型分布及0赔付案件案件数据
export const getCompensateCaseDetails = (params) => {
   return SystemAxios.axios({
      url: '/ins-claims/largeScreenClaim/getCompensateCaseDetails',
      method: 'get',
      params
   });
};

// 获取服务流程数据
export const getCaseProcessDetails = (params) => {
   return SystemAxios.axios({
      url: '/ins-claims/largeScreenClaim/getCaseProcessDetails',
      method: 'get',
      params
   });
};

// -----------------------------------风控在线--------------------------------------------------------

// 获取报警统计
export const getAlarmStatistics = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getAlarmStatistics',
      method: 'get',
      params
   });
};

// 获取实时报警信息
export const getRealTimeAlarms = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getRealTimeAlarms',
      method: 'get',
      params
   });
};

// 获取报警视频信息
export const getAlarmVideos = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getAlarmVideos',
      method: 'get',
      params
   });
};

// 获取实时监控
export const getMonitorDevices = (data) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getMonitorDevices',
      method: 'post',
      data
   });
};

// 获取筛选车组
export const getGroupInfo = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getGroupInfo',
      method: 'get',
      params
   });
};

// 获取筛选类型
export const getMachineryEquipmentType = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getMachineryEquipmentType',
      method: 'get',
      params
   });
};

// 获取筛选时长
export const getAlarmTimeType = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getAlarmTimeType',
      method: 'get',
      params
   });
};

// 获取筛选时长 2
export const getWorkingTimeType = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getWorkingTimeType',
      method: 'get',
      params
   });
};

// 获取TTS语音列表
export const getTtsList = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/ttsSetting/getTtsList',
      method: 'get',
      params
   });
};

// 新增/修改TTS语音
export const saveTtsSetting = (data) => {
   return statisticsAxios.axios({
      url: '/bigScreen/ttsSetting/saveTtsSetting',
      method: 'post',
      data
   });
};

// 删除TTS语音
export const delTtsList = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/ttsSetting/getTtsList',
      method: 'get',
      params
   });
};

// 机手信息
export const getDriverInfo = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getDriverInfo',
      method: 'get',
      params
   });
};

// 获取监控详情
export const getMonitorDetails = (params) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlOnline/getMonitorDetails',
      method: 'get',
      params
   });
};

// 添加干预次数
export const addTtsSettingNum = (data) => {
   return statisticsAxios.axios({
      url: '/bigScreen/riskControlIntervene/saveTtsSetting',
      method: 'post',
      data
   });
};


// -----------------------------------设备档案--------------------------------------------------------

// 设备信息
export const carDeviceDetails = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceFiles/deviceDetails',
      method: 'post',
      data
   });
};

// 保险、理赔信息
export const carPolicyClaims = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceFiles/policyClaims',
      method: 'post',
      data
   });
};

// 设备工时、告警信息
export const carWorkAndAlarm = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceFiles/workAndAlarm',
      method: 'post',
      data
   });
};


// -----------------------------------设备信息--------------------------------------------------------

// 设备信息
export const customInfoDetails = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceCustomInfo/details',
      method: 'post',
      data
   });
};

// 地图信息
export const customMapData = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceCustomInfo/mapData',
      method: 'post',
      data
   });
};


// -----------------------------------客户信息--------------------------------------------------------

// 设备统计
export const deviceNumberStatistics = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerInfo/deviceNumberStatistics',
      method: 'post',
      data
   });
};

// 地图信息
export const customerMapData = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerInfo/mapData',
      method: 'post',
      data
   });
};

// 用户设备
export const customerUserDevice = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerInfo/userDevice',
      method: 'post',
      data
   });
};

// 用户类型、评分规则
export const userTypeScoringRule = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerInfo/userTypeScoringRule',
      method: 'post',
      data
   });
};



// -----------------------------------机械设备--------------------------------------------------------

// 分布类型、零配件供应商
export const mechanicalSpareParts = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceMechanical/mechanicalSpareParts',
      method: 'post',
      data
   });
};

// 硬件报警
export const mechanicalAlarm = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceMechanical/alarm',
      method: 'post',
      data
   });
};

// 地图数据
export const mechanicalMapData = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceMechanical/mapData',
      method: 'post',
      data
   });
};

// 保费规模、保费趋势、险种
export const mechanicalPolicyScale = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceMechanical/policyScale',
      method: 'post',
      data
   });
};

// 车辆类型
export const mechanicalDeviceTypeImage = (data) => {
   return YWAxios.axios({
      url: '/basic-data/deviceMechanical/deviceTypeImage',
      method: 'post',
      data
   });
};


// -----------------------------------保险产品--------------------------------------------------------


// 赔付情况
export const insuranceClaimStatus = (data) => {
   return YWAxios.axios({
      url: '/basic-data/insuranceProduct/claimStatus',
      method: 'post',
      data
   });
};

// 赔付类型占比、0赔付案件情况
export const claimTypeZeroClaimCaseCondition = (data) => {
   return YWAxios.axios({
      url: '/basic-data/insuranceProduct/claimTypeZeroClaimCaseCondition',
      method: 'post',
      data
   });
};

// 险种占比
export const insuranceTypeThan = (data) => {
   return YWAxios.axios({
      url: '/basic-data/insuranceProduct/insuranceTypeThan',
      method: 'post',
      data
   });
};

// 地图数据
export const insuranceMapData = (data) => {
   return YWAxios.axios({
      url: '/basic-data/insuranceProduct/mapData',
      method: 'post',
      data
   });
};

// 产品方案、承保公司
export const productSchemeUnderwritingCompany = (data) => {
   return YWAxios.axios({
      url: '/basic-data/insuranceProduct/productSchemeUnderwritingCompany',
      method: 'post',
      data
   });
};

// 保费规模
export const insurancePremiumScale = (data) => {
   return YWAxios.axios({
      url: '/basic-data/insuranceProduct/premiumScale',
      method: 'post',
      data
   });
};



// -----------------------------------客户档案--------------------------------------------------------


// 赔付统计
export const customerClaimsStatistics = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerArchives/claimsStatistics',
      method: 'post',
      data
   });
};

// 个人信息
export const customerPersonalInfo = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerArchives/customerPersonalInfo',
      method: 'post',
      data
   });
};

// 设备规模
export const customerEquipmentScale = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerArchives/equipmentScale',
      method: 'post',
      data
   });
};

// --
export const customerPageData = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerArchives/pageData',
      method: 'post',
      data
   });
};

// 保费规模、保费历史
export const customerPremiumScaleHistory = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerArchives/premiumScaleHistory',
      method: 'post',
      data
   });
};

// 风险分析
export const customerRiskAnalysis = (data) => {
   return YWAxios.axios({
      url: '/basic-data/customerArchives/riskAnalysis',
      method: 'post',
      data
   });
};



// -----------------------------------保司档案--------------------------------------------------------


// 保司档案数据
export const insuranceProductData = (data) => {
   return YWAxios.axios({
      url: '/basic-data/insuranceProduct/pageData',
      method: 'post',
      data
   });
};