<template>
  <div class="model-box">
    <div class="car">
      <div class="car-name">
        <img
          src="../../../../assets/images/bigScreen/riskVideo/car-icon.png"
          alt=""
          srcset=""
        />
        设备名称：{{ monitorDetails.plate }}
      </div>
      <div class="car-number">机身编号：{{ monitorDetails.frameNo }}</div>
    </div>
    <div class="intervene">
      <div>
        <div class="intervene-text">本月告警次数</div>
        <div class="intervene-num">{{ monitorDetails.monthAlarmCount }}</div>
      </div>
      <div>
        <div class="intervene-text">本月干预次数</div>
        <div class="intervene-num">{{ monitorDetails.monthAlarmInterventionCount }}</div>
      </div>
    </div>
    <div class="operator">
      <div class="operator-title">机手信息</div>
      <div class="operator-content">
        <el-image
          style="width: 100px; height: 100px"
          :src="driverInfo.headImage || 'https://gimg3.baidu.com/search/src=https%3A%2F%2Ffeed-image.baidu.com%2F0%2Fpic%2F1614361804_-2055155_-1155597071.jpg&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1694019600&t=d99bfbee9520cf1b604ea51c59ce99f0'"
          class="operator-content-img"
          fit="cover"
        ></el-image>
        <div class="operator-content-text">
          <div>姓名：{{ driverInfo.driverName }}</div>
          <div>联系方式：{{ driverInfo.phone }}</div>
          <div>工作年限：{{ driverInfo.workingYears }}年</div>
          <div>受训次数：{{ driverInfo.trainedTimes }}次</div>
          <div>证件信息：{{ driverInfo.certificateNo }}</div>
          <div>事故次数：{{ driverInfo.accidentsTimes }}次</div>
        </div>
      </div>
    </div>
    <header-name class="tendency-title" title="监控干预" />
    <div class="operation-box" ref="operation-box" v-if="!isOperation">
      <div
        :style="{ height: boxH + 'px', width: boxH + 'px' }"
        @click="handleSpeak(true)"
      >
        <img
          src="../../../../assets/images/bigScreen/riskVideo/intercom-icon.png"
          alt=""
          srcset=""
        />
        <div>对讲</div>
      </div>
      <div
        :style="{ height: boxH + 'px', width: boxH + 'px' }"
        @click="isOperation = 2"
      >
        <img
          src="../../../../assets/images/bigScreen/riskVideo/phone-icon.png"
          alt=""
          srcset=""
        />
        <div>致电监工</div>
      </div>
      <div
        :style="{ height: boxH + 'px', width: boxH + 'px' }"
        @click="isOperation = 3"
      >
        <img
          src="../../../../assets/images/bigScreen/riskVideo/call-icon.png"
          alt=""
          srcset=""
        />
        <div style="color: rgba(255, 66, 0, 1)">一键报警</div>
      </div>
    </div>
    <div class="operation-box operation-bg" v-else>
      <div class="operation-box-intercom" v-if="isOperation == 1">
        <img
          class="operation-box-intercom-icon"
          src="../../../../assets/images/bigScreen/riskVideo/intercom-icon.png"
          alt=""
          srcset=""
        /><img class="operation-box-intercom-icon"
          src="../../../../assets/images/bigScreen/riskVideo/intercom-on.png"
          alt=""
          srcset=""
        />
        <div class="operation-box-intercom-button" @click="handleSpeak(false)">
          结束对讲
        </div>
      </div>
      <div class="operation-box-call" v-else>
        <div class="operation-box-call-text">
          拨打{{ isOperation == 2 ? "监工" : "报警" }}电话
        </div>
        <div class="operation-box-call-num">{{ '400-1685 885' }}</div>
        <div class="operation-box-call-button">
          <div class="call-button1" @click="isOperation = null">取消</div>
          <div class="call-button2" @click="isOperation = null">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderName from "@/views/BigScreenDisplay/components/HeaderName";
import { getDriverInfo, addTtsSettingNum } from "@/api/BigScreenDisplay";
export default {
  components: { HeaderName },
  data() {
    return {
      boxH: 0,
      isOperation: null,
      operatorImg: require('../../../../assets/images/bigScreen/riskVideo/intercom-icon.png'),
      driverInfo: {}
    };
  },
  props: {
    monitorDetails: {
      type: Object,
      default: ()=>{}
    }
  },
  watch: {
    isOperation: {
      handler(v) {
        v ? this.handleAddTtsSettingNum() : this.init();
      },
      deep: true
    },
    monitorDetails: {
      handler() {
        this.init();
      }
    }
  },
  created() {
  },
  methods: {
    init() {
      getDriverInfo({vehicleId: this.monitorDetails.vehicleId}).then(res=>{
        this.driverInfo = res.obj;
      })
       this.$refs["operation-box"] ? this.$nextTick(() => {
        let h1 = this.$refs["operation-box"].offsetWidth * 0.3;
        let h2 = this.$refs["operation-box"].offsetHeight;
        this.boxH = h1 > h2 ? h2 : h1;
      }) : '';
    },
    handleSpeak(type) {
      this.$emit('handleSpeak', type);
      type ? this.isOperation = 1 : this.isOperation = null
    },
    handleAddTtsSettingNum() {
      addTtsSettingNum({
        "vehicleId": this.monitorDetails.vehicleId, //车辆ID
        "groupId": this.monitorDetails.groupId, //车组ID
        "intervention": this.isOperation == 1 ? '对讲' : this.isOperation == 2 ? '致电监工' : '一键报警', //干预内容
        "interventionType": this.isOperation, //干预类型: 1-对讲 2-致电监工 3-一键报警 4-TTS语音下发
        "interventionTypeName": this.isOperation == 1 ? '对讲' : this.isOperation == 2 ? '致电监工' : '一键报警' //干预类型描述: 1-对讲 2-致电监工 3-一键报警 4-TTS语音下发
      }).then(res=>{
      console.log(23232332323);
        this.$nextTick(()=>{
          this.$emit('changeIntervene')
        })
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  padding: 4%;
  .car {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-size: 0.14rem;
    margin-bottom: 4%;
    &-name {
      display: flex;
      align-items: center;
      img {
        height: 0.16rem;
        margin-right: 4px;
      }
    }
    &-number {
    }
  }
  .intervene {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 5%;
    margin-bottom: 4%;
    > div {
      display: flex;
      width: calc(50% - 8px);
      height: 100%;
      background-image: url("../../../../assets/images/bigScreen/riskVideo/intervene-item.png");
      background-size: 100% 100%;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &-text {
      font-size: 0.14rem;
      color: #ffffff;
      flex: 1;
    }
    &-num {
      font-family: "ZhanKuQingKeHuangYouTi";
      font-size: 0.16rem;
      color: rgba(255, 253, 55, 1);
      width: 46%;
    }
  }
  .operator {
    height: 40%;
    padding: 1.6%;
    background-image: url("../../../../assets/images/bigScreen/riskVideo/operator-bg.png");
    background-size: 100% 100%;
    margin-bottom: 6%;
    &-title {
      display: flex;
      align-items: center;
      height: 13%;
      padding-left: 3.5%;
      font-size: 0.14rem;
      font-weight: bold;
      color: #ffffff;
    }
    &-content {
      padding: 3.2%;
      height: 88%;
      display: flex;
      &-img {
        width: 34.3% !important;
        height: 100% !important;
        border: 1px solid rgba(2, 103, 203, 1);
        border-radius: 4px;
      }
      &-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 3.5%;
        > div {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 0.14rem;
          color: #ffffff;
        }
      }
    }
  }
  .tendency-title {
    margin-bottom: 6%;
  }
  .operation-box {
    display: flex;
    justify-content: space-between;
    height: 32%;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 30%;
      background-image: url("../../../../assets/images/bigScreen/riskVideo/operation-item.png");
      background-size: 100% 100%;
      cursor: pointer;
      > img {
        width: 18%;
        margin-bottom: 6%;
      }
      > div {
        font-size: 0.14rem;
        font-weight: bold;
        color: #ffffff;
      }
    }
    &-intercom {
      position: relative;
      padding: 4%;
      flex-direction: row !important;
      width: 100% !important;
      background-image: none !important;
      cursor: auto !important;
      &-icon {
        width: auto !important;
        height: 50%;
        margin-bottom: 0 !important;
        margin-right: 10%;
      }
      &-button {
        position: absolute;
        right: 4%;
        top: 9%;
        font-size: 0.14rem;
        color: #ffffff;
        padding: 0.08rem 0.16rem;
        background-image: url("../../../../assets/images/bigScreen/riskVideo/intercom-end.png");
        background-size: 100% 100%;
        cursor: pointer;
      }
    }
    &-call {
      width: 40% !important;
      background-image: none !important;
      cursor: auto !important;
      color: #ffffff;
      &-text {
        text-align: center;
        font-size: 0.16rem;
        font-weight: bold;
        margin-bottom: 10%;
      }
      &-num {
        font-size: 0.24rem;
        font-weight: bold;
        margin-bottom: 20%;
      }
      &-button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        > div {
          padding: 0.05rem 0.14rem;
          color: #ffffff;
          font-size: 0.14rem;
          cursor: pointer;
          background-size: 100% 100%;
        }
        .call-button1 {
          background-image: url("../../../../assets/images/bigScreen/riskVideo/intercom-end.png");
        }
        .call-button2 {
          background-image: url("../../../../assets/images/bigScreen/riskVideo/intercom-end2.png");
        }
      }
    }
  }
  .operation-bg {
    justify-content: center !important;
    background-image: url("../../../../assets/images/bigScreen/riskVideo/operation-bg.png");
    background-size: 100% 100%;
  }
}
</style>