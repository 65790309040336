
/**
 * 比较两个数组
 * @return {[addItems, updateItems, delItems]} addItems: 新加数据项; delItems: 删除的数据项; updateItems: 更新的数据项
 */
export function diffPatch(newItems, oldItems, compareCb = () => { }) {

  // 新加: `newItems`有, 但`oldItems`没有, 则新加
  // 删除: `newItems`没有, 但`oldItems`有, 则删除
  // 更新: `newItems` `oldItems`都有, `lng`和`lat`不同, 则数据有更新, 以`newItems`中的数据合并数据

  // 新加的数据项
  const addItems = [...newItems];
  // 删除的数据项
  const delItems = [...oldItems];
  // 更新的数据项
  const updateItems = [];

  let newIdx = addItems.length;
  while (newIdx > 0) {
    newIdx--;
    const addItem = addItems[newIdx];

    let oldIdx = delItems.length;
    while (oldIdx > 0) {
      oldIdx--;
      const delItem = delItems[oldIdx];

      // 相同点, 更新
      if (compareCb(addItem, delItem)) {
        // 更新, 合并
        updateItems.push({
          ...delItem,
          ...addItem
        });
        addItems.splice(newIdx, 1);
        delItems.splice(oldIdx, 1);
        break;
      }
    }
  }

  return [
    addItems,
    updateItems,
    delItems
  ];
}