<template>
  <div class="camera-box" ref="camera-box">
    <div class="camera-box-item" :class="{ 'camera-box-item-s': channel == item.cameraLine }" :style="{ height: boxH + 'px' }" v-for="item,index in cameraLineVoList" :key="index">
      <div class="camera-box-item-name">{{ item.cameraLineName }}</div>
      <div class="camera-box-item-camera">
        <i class="el-icon-caret-right camera-box-item-camera-icon" :class="{ 'camera-box-item-camera-d': channel == item.cameraLine }" @click="handlePlay(item.cameraLine)"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxH: 0,
    };
  },
  props: {
    cameraLineVoList: {
      type: Array,
      default: ()=> []
    },
    channel: {
      type: [Number,String],
      default: null
    }
  },
  watch: {
    cameraLineVoList: {
      handler() {
        this.init();
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.boxH = this.$refs["camera-box"].offsetWidth;
      });
    },
    handlePlay(data) {
      this.$emit('update:channel', data)
    }
  },
};
</script> 

<style lang="scss" scoped>
.camera-box {
  height: 100%;
  overflow: auto;
  &-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url("../../../../assets/images/bigScreen/riskVideo/camera-item.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 1%;
    margin-bottom: 5%;
    &-name {
      display: flex;
      align-items: center;
      font-size: 0.14rem;
      color: #ffffff;
      padding-left: 2px;
      height: 10%;
    }
    &-camera {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url("../../../../assets/images/bigScreen/risk/monitor-video.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
        &-icon {
          color: #ffffff;
          font-weight: bold;
          font-size: 0.24rem;
          background-color: rgba(154, 154, 154, 1);
          border-radius: 50%;
          padding: 0.06rem;
          cursor: pointer;
        }
        &-d {
          cursor: not-allowed !important;
        }
    }
    &-s {
      background-image: url("../../../../assets/images/bigScreen/riskVideo/camera-item-s.png");
    }
  }
}
</style>