<template>
  <div class="model-box">
    <Camera
      ref="camera"
      :cameraLineVoList="monitorDetails.cameraLineVoList"
      :channel.sync="channel"
      class="camera"
    />
    <BigVideo
      ref="bigVideo"
      :monitorDetails="monitorDetails"
      :channel="channel"
      style="margin-left: 0.6%"
      class="video"
      :isSpeak="isSpeak"
    />
  </div>
</template>

<script>
import Camera from "@/views/BigScreenDisplay/components/riskVideo/Camera";
import BigVideo from "@/views/BigScreenDisplay/components/riskVideo/BigVideo";
export default {
  components: { Camera, BigVideo },
  props: {
    isSpeak: {
      type: Boolean,
      default: false,
    },
    monitorDetails: {
      type: Object,
      default: () => {
        cameraLineVoList: [];
      },
    },
  },
  data() {
    return {
      channel: null,
    };
  },
  created() {
    this.channel = this.$route.query.channel;
  },
  methods: {
    init() {
      this.$nextTick(() => {
        let list = ["camera"];
        list.map((val) => {
          this.$refs[val].init();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.model-box {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.2%;
  .camera {
    width: 14%;
  }
  .video {
    flex: 1;
  }
}
</style>
