<template>
  <div class="yili-radio" @click="handleClick" :style="{ flexDirection: position == 'end' ? 'row-reverse' : '', cursor: disabled ? 'no-drop' : ''}">
    <div class="yili-radio-item" :class="{ 'yili-radio-citem': value }" :style="{ height: ((size + '').indexOf('px') > -1 || (size + '').indexOf('rem') > -1) ? size : size + 'px', width: ((size + '').indexOf('px') > -1 || (size + '').indexOf('rem') > -1) ? size : size + 'px' }"></div>
    <div class="yili-radio-name" :style="{fontSize: ((size + '').indexOf('px') > -1 || (size + '').indexOf('rem') > -1) ? size : size + 'px', color: color }">{{ name }}</div>
  </div>
</template>

<script>
export default {
 props: {
  value: {
    type: [String,Boolean,Number],
    default: false
  },
  label: {
    type: [String,Boolean,Number],
    default: true
  },
  name: {
    type: String,
    default: ''
  },
  size: {
    type: [String,Number],
    default: '14px'
  },
  color: {
    type: String,
    default: '#ffffff'
  },
  position: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
 },
  model: {
    prop: 'value',
    event: 'input'
  },
  methods: {
    handleClick() {
      if (this.disabled) {
        return false;
      }
      this.value === this.label ? this.$emit('input', '') : this.$emit('input', this.label)
    }
  }
}
</script>

<style lang="scss" scoped>
.yili-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  &-item {
    position: relative;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-right: 4px;
  }
  &-name {
    margin-right: 4px;
  }
  &-citem {
    position: relative;
    border: 1px solid #96F5F8;
  }
  &-citem::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    background-color: #96F5F8;
    border-radius: 50%;
  }
}
</style>