<template>
  <div class="RtpPlayerWrapper">
    <div class="Header" v-if="showParameter && isConditions">
      <el-button class="mr10" @click.stop="onCloseAll">全部关闭</el-button>
      <el-button class="mr10" @click.stop="onHDAll">{{ HDName }}</el-button>
      <el-select class="wpx100 mr10" v-model="vdoGrid">
        <el-option
          v-for="gridObj in videoGridOptions"
          :key="gridObj.value"
          :value="gridObj.value"
          :label="gridObj.label"
        ></el-option>
      </el-select>
    </div>

    <div class="Content">
      <div :id="playerId" class="playerContainer" ref="playerRef"></div>
    </div>
  </div>
</template>

<script>
import { throttle } from "lodash";
import { mapState } from "vuex";
import { appConfig } from "@/config/appConfig";
import { randomNumber } from "@/utils/random";
import { sleep } from "@/utils/sleep";
import ByskPlayer from "@/components/ByskPlayer";
import { diffPatch } from "@/utils/diffPatch";
import { getTreeNodeId } from "@/utils/treeHelper";

const videoGridOptions = [
  {
    value: 1,
    label: "1行x1列",
  },
  {
    value: 4,
    label: "2行x2列",
  },
  {
    value: 9,
    label: "3行x3列",
  },
  {
    value: 16,
    label: "4行x4列",
  },
  {
    value: 25,
    label: "5行x5列",
  },
];

// 对接车辆 单例
let _speakVehicle = null;

export default {
  name: "RtpPlayer",

  props: {
    // 播放视频列表
    videoList: {
      type: Array,
      default() {
        return [];
      },
    },
    // 对讲车辆
    speakVehicle: {
      type: Object,
      default: null,
    },
    // 视频窗口布局
    videoGrid: {
      type: Number,
      default: 4,
    },
    // 定时关闭视频（分钟，0表示从不）
    videoCloseTime: {
      type: Number,
      default: 0,
    },
    Z: {
      type: Number,
    },
    realZ: {
      type: Number,
    },
    showParameter: {
      type: Boolean,
      default: true,
    },
    isConditions: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    this.playerObj = null;
    this.channelMap = new Map();

    return {
      videoGridOptions,
      playerId: `live-${randomNumber()}`,
      // 视频布局
      vdoGrid: 4,
      HDName: "一键高清",
    };
  },
  computed: {
    ...mapState(["userInfo", "appToken", "userConfig"]),
    // 中国石油判断
    isZgsy() {
      return (
        this.$store.state.userRouter?.children?.find(
          (r) => r.code === "zgsy"
        ) && this.$store.state.userInfo.name != "广东一立科技"
      );
    },
  },
  watch: {
    videoCloseTime(val) {
      console.log(val, "videoCloseTime");
      const { playerObj } = this;
      if (playerObj) {
        playerObj.autoCloseTime = val;
      }
    },
    Z: function (val) {
      console.log("---------", val);
      if (val == 9) {
        this.onCloseAll();
        this.$message.error("设备已过期，无法查看视频");
      } else if (val == 4 && this.isZgsy) {
        this.onCloseAll();
        this.$message.error("设备已熄火，无法查看视频");
      } else if (val == 5) {
        this.onCloseAll();
        this.$message.error("设备已离线，无法查看视频");
      }
    },
    realZ(val) {
      if (val == 9) {
        const { channelMap } = this;
        const channelObjs = [...channelMap.values()];
        channelObjs.forEach((channelObj) => this.closeChannelVideo(channelObj));
        this.$message.error("设备已过期，无法查看视频");
      } else if (val == 4 && this.isZgsy) {
        const { channelMap } = this;
        const channelObjs = [...channelMap.values()];
        channelObjs.forEach((channelObj) => this.closeChannelVideo(channelObj));
        this.$message.error("设备已熄火，无法查看视频");
      } else if (val == 5) {
        const { channelMap } = this;
        const channelObjs = [...channelMap.values()];
        channelObjs.forEach((channelObj) => this.closeChannelVideo(channelObj));
        this.$message.error("设备已离线，无法查看视频");
      }
      // if (val == 0) {
      //   // this.videoList = [];
      //   const { channelMap } = this;
      //   const channelObjs = [...channelMap.values()];
      //   channelObjs.forEach((channelObj) => this.closeChannelVideo(channelObj));
      //   this.$message.error("设备已离线，无法查看视频");
      // }
    },
    videoGrid: {
      immediate: true,
      handler(val) {
        this.vdoGrid = val;
      },
    },
    vdoGrid(val) {
      const { playerObj } = this;
      playerObj && playerObj.setPlayerNum(val);
    },
    videoList(newArr, oldArr) {
      if (this.playerObj) {
        this.expandTheVideo(newArr, oldArr);
      }
    },
    async speakVehicle(newObj) {
      if (_speakVehicle && newObj) {
        // 提示正在对讲
        this.$notify({
          title: "对讲警告",
          message: `请先关闭 ${_speakVehicle.plate} 对讲`,
          type: "warning",
        });
        this.emitSepakEnd(_speakVehicle);
        return;
      }

      if (_speakVehicle && !newObj) {
        // 关闭对讲
        await this.closeSpeak(_speakVehicle);
        this.$message.warning("对讲已关闭");
        await this.$nextTick();
      }

      if (newObj) {
        // 开始对讲
        _speakVehicle = newObj;
        this.openSpeak(newObj);
      }
    },
  },
  beforeMount() {
    this.onPlayStart = this.onPlayStart.bind(this);
    this.onPlayEnd = this.onPlayEnd.bind(this);
    this.onRegister = this.onRegister.bind(this);
    this.onPlayerMessage = this.onPlayerMessage.bind(this);
  },
  mounted() {
    this.initPlayer();
    // 获取系统设置的值
    // this.videoGrid = this.userConfig.realTimeConfig.videoGrid;
  },
  beforeDestroy() {
    const { playerObj } = this;
    if (playerObj) {
      playerObj.destroy();
    }
    this.playerObj = null;
  },
  methods: {
    // 开始对讲
    openSpeak(params) {
      const { device, protocolType, SpecialSign } = params;
      this.playerObj.real.openSpeak(
        {
          device,
          protocolType,
          SpecialSign,
        },
        (res) => {
          if (![1, 2].includes(res.status) && res.info) {
            // 失败
            this.$notify({
              title: "对讲警告",
              message: res.info ? res.info : `${params.plate} 对讲打开失败`,
              type: "warning",
            });
            this.emitSepakEnd(_speakVehicle);
            _speakVehicle = null;
          } else if (res.status === 1) {
            // 成功
            this.$notify({
              title: "对讲提示",
              message: `${params.plate} 对讲打开成功`,
              type: "success",
            });
          }
        }
      );
    },
    // 关闭对讲
    closeSpeak(params) {
      _speakVehicle = null;
      return new Promise((resolve) => {
        const { device, protocolType } = params;
        this.playerObj.real.closeSpeak(
          {
            device,
            protocolType,
          },
          (res) => {
            resolve();
          }
        );
      });
    },

    // 打开视频
    playChannelVideo(channelObj) {
      this.HDName = "一键高清";
      return new Promise((resolve) => {
        const { playerObj } = this;

        if (!playerObj) return resolve(false);

        if (this.channelMap.has(channelObj.key)) return resolve(false);
        this.videoGrid == 1 ? playerObj.player.initPlayers() : "";
        let { flag, ids } = playerObj.allocate(
          this.videoGrid == 1 ? playerObj.player.displayNum : 1
        );

        if (!(flag && ids.length)) return resolve(false);
        const tid = ids[0];
        this.channelMap.set(channelObj.key, {
          tid,
          ...channelObj,
        });

        this.playerObj.real.open(
          tid,
          {
            device: channelObj.device,
            channel: channelObj.channel,
            channelsName: channelObj.channelsName,
            protocolType: channelObj.protocolType,
            SpecialSign: channelObj.SpecialSign,
            codeTypeCtrlBtnEnabled: true,
            plate: channelObj.plate,
            vehicleId: channelObj.vehicleId,
            groupId: channelObj.groupId,
          },
          (res) => {
            if (![1, 2].includes(res.status)) {
              return resolve(false);
            }
            return resolve(true);
          }
        );
      });
    },
    // 关闭视频
    closeChannelVideo(channelObj) {
      if (!this.channelMap.has(channelObj.key)) return;
      this.channelMap.delete(channelObj.key);
      this.playerObj.real.close(null, {
        device: channelObj.device,
        channel: channelObj.channel,
        protocolType: channelObj.protocolType,
      });
    },

    async initPlayer() {
      await this.$nextTick();
      const { playerId, userInfo, appToken, videoCloseTime } = this;
      console.log(
        this.$route.name == "foreignBigScreenDisplayVideoPolling" ||
          this.$route.name == "foreignVideoPolling"
          ? "wss://120.78.185.121:8899"
          : appConfig.baseMediaUrl
      );
      const baseURL =
        this.$route.name == "foreignBigScreenDisplayVideoPolling" ||
        this.$route.name == "foreignVideoPolling"
          ? "wss://120.78.185.121:8899"
          : appConfig.baseMediaUrl;

      const player = new ByskPlayer({
        id: playerId,
        isReal: true,
        baseURL,
        userId: userInfo.name,
        showParameter: this.showParameter,
        userkey: appToken,
        playstart: this.onPlayStart,
        playend: this.onPlayEnd,
        register: this.onRegister,
        message: this.onPlayerMessage,
      });

      this.playerObj = player;

      await this.$nextTick();

      player.setPlayerNum(this.vdoGrid);
      if (videoCloseTime > 0) {
        player.autoCloseTime = videoCloseTime;
      };
      if (this.videoList && this.videoList.length) {
        this.$nextTick(()=>{
          this.expandTheVideo(this.videoList, []);
        })
      }
    },
    onPlayStart(obj) {
      const key = getTreeNodeId(obj.groupId, obj.vehicleId, obj.channel);
      const channelObj = {
        key,
        device: obj.device,
        channel: obj.channel,
        channelsName: obj.channelsName,
        protocolType: obj.protocolType,
        plate: obj.plate,
        vehicleId: obj.vehicleId,
        groupId: obj.groupId,
      };

      this.channelMap.set(key, {
        tid: obj.vid,
        ...channelObj,
      });

      this.$nextTick(() => {
        this.emitPlayStart(channelObj);
      });
    },
    onPlayEnd(obj) {
      const key = getTreeNodeId(obj.groupId, obj.vehicleId, obj.channel);
      const channelObj = this.channelMap.get(key);
      this.channelMap.delete(key);
      this.emitPlayEnd(channelObj);
    },
    onRegister() {},
    onPlayerMessage(obj) {},
    async onCloseAll() {
      const { channelMap } = this;
      const channelObjs = [...channelMap.values()];
      channelObjs.forEach((channelObj) => this.closeChannelVideo(channelObj));

      await this.$nextTick();

      channelObjs.forEach((channelObj) => this.emitPlayEnd(channelObj));
    },
    // 一键切换高清/标清
    onHDAll() {
      const { channelMap } = this;
      const channelObjs = [...channelMap.values()];
      if (channelObjs && channelObjs.length < 1) {
        this.$message.warning("没有视频可以切换");
        return false;
      }
      this.HDName = this.HDName == "一键标清" ? "一键高清" : "一键标清";
      channelObjs.map((val, ind) => {
        if (
          this.HDName == "一键标清" &&
          this.playerObj.player.$data.players[ind].codetype == 1
        ) {
          this.playerObj.player.$data.players[ind].video.src = "";
          this.playerObj.player.onClickCtrlCodetype(
            this.playerObj.player.$data.players[ind],
            0
          );
        } else if (
          this.HDName == "一键高清" &&
          this.playerObj.player.$data.players[ind].codetype == 0
        ) {
          this.playerObj.player.$data.players[ind].video.src = "";
          this.playerObj.player.onClickCtrlCodetype(
            this.playerObj.player.$data.players[ind],
            1
          );
        }
      });
    },
    emitPlayStart(channelObj) {
      this.$emit("playStart", channelObj);
    },
    emitPlayEnd(channelObj) {
      this.$emit("playEnd", channelObj);
    },
    emitSepakEnd(vehicleObj) {
      this.$emit("speakEnd", vehicleObj);
    },
    expandTheVideo(newArr, oldArr) {
      console.log(newArr);
      const [addArr, , delArr] = diffPatch(
        newArr,
        oldArr,
        (newObj, oldObj) => newObj.key === oldObj.key
      );
      // 关闭视频
      if (delArr.length > 0) {
        delArr.forEach((channelObj) => {
          this.closeChannelVideo(channelObj);
        });
      }
      this.$nextTick(() => {
        // 新增播放视频
        if (addArr.length > 0) {
          addArr.forEach((channelObj) => {
            this.playChannelVideo(channelObj).then((flag) => {
              if (flag) return;
              // 播放失败
              this.emitPlayEnd(channelObj);
            });
          });
        }
        if (newArr.length > this.vdoGrid) {
          if (this.vdoGrid == 1) {
            this.vdoGrid = 4;
          } else if (this.vdoGrid == 4) {
            this.vdoGrid = 9;
          } else if (this.vdoGrid == 9) {
            this.vdoGrid = 16;
          } else if (this.vdoGrid == 16) {
            this.vdoGrid = 25;
          }
        }
      });
    
    }
  },
};
</script>

<style lang="scss" scoped>
.RtpPlayerWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Header {
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.Content {
  flex: 1;
  overflow: hidden;
}

.playerContainer {
  flex: 1;
  height: 100%;
  width: 100%;
  display: block;
}

.wpx100 {
  width: 100px;
}

.mr10 {
  margin-right: 10px;
}
</style>
