<template>
  <div id="BigScreenDisplay" ref="BigScreenDisplay" class="data-center">
    <!-- 布局头部 -->
    <div class="header" :class="{ bg1: header1, bg4: header4 }">
      <!-- :class="{'bg1':header1,'bg1':header2,'bg1':header3}" -->
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/equipment/goHome.png"
          @click="handleGoPath('BigScreenDisplay')"
          class="go-home"
          alt=""
          srcset=""
        />
      </div>
      <div class="header_text_weather">
        <i class="el-icon-location"></i>
        <span style="margin-left: 0.5em">{{ weatherArea }}</span>
        <img class="header_weather" :src="weatherImg" alt="" />
        <span style="margin-left: 0.5em">{{ weatherText }}</span>
      </div>
      <span style="flex: 2"></span>
      <span class="time-pane">
        {{ dateText }}
        <span style="margin: 0 0.5em">{{ dayNum }}</span>
        {{ timeStr }}
      </span>
      <div class="path-left">
        <img
          src="../../assets/images/bigScreen/equipment/enlarge.png"
          class="go-home"
          style="margin-left: 0.3rem; margin-right: 0"
          @click="handleClick"
          alt=""
          srcset=""
        />
      </div>
    </div>

    <!-- 布局中间部分 -->
    <div class="MainOuter">
      <div class="heartland-box background-box">
        <monitor
          ref="monitor"
          :monitorDetails="monitorDetails"
          :isSpeak="isSpeak"
        />
      </div>
      <div class="flex-direction flex_1" style="overflow: hidden">
        <div class="mechanical-box background-box">
          <div style="height: 4.5%" class="hold-box"></div>
          <Mechanical
            ref="mechanical"
            :monitorDetails="monitorDetails"
            style="height: 95.5%"
            @handleSpeak="(v) => (isSpeak = v)"
            @changeIntervene="init"
          />
        </div>
        <div class="voice-box background-box">
          <div style="height: 8.9%" class="hold-box"></div>
          <span
            class="hold-box-center"
            style="display: flex"
            v-if="!isEditVoice"
          >
            <img
              class="voice-set"
              src="../../assets/images/bigScreen/riskVideo/voice-set.png"
              @click="handlerEditVoice"
              alt=""
              srcset=""
            />
            <div class="voice-button" @click="handleVoice">确定</div>
          </span>
          <span class="hold-box-center" style="display: flex" v-else>
            <div class="voice-button" @click="handlerEditVoice">确定</div>
          </span>
          <Voice
            :monitorDetails="monitorDetails"
            style="height: 91.1%"
            @changeIntervene="init"
            @changeEdit="(v) => (isEditVoice = v)"
            ref="voice-box"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// --------------------------------------------地址及天气查询--------------------------------
import { weatherInfo, amapGetIp } from "@/api/weather";
// --------------------------------------------模块组件--------------------------------------------
import monitor from "@/views/BigScreenDisplay/components/riskVideo/monitor";
import Mechanical from "@/views/BigScreenDisplay/components/riskVideo/Mechanical";
import Voice from "@/views/BigScreenDisplay/components/riskVideo/Voice";
// --------------------------------------------接口--------------------------------------------
import { getMonitorDetails } from "@/api/BigScreenDisplay";
// --------------------------------------------工具--------------------------------------------
import dayjs from "dayjs";
import moment from "dayjs";
import { mapMutations } from "vuex";

export default {
  // `name`与路由保持一致
  // name: "BigScreenDisplayRiskVideo",
  components: {
    monitor,
    Mechanical,
    Voice,
  },
  data() {
    // this.intervalId = null;
    return {
      header1: true,
      header2: false,
      header3: false,
      header4: false,
      timeStr: "", // 时间
      timer: null, // 时间定时器
      weatherArea: "",
      weatherImg: "",
      weatherText: "",
      dateText: "",
      dayNum: "",
      timeOptions: [
        {
          value: "2024",
          label: "2024年",
        },
        {
          value: "2023",
          label: "2023年",
        },
        {
          value: "2022",
          label: "2022年",
        },
        {
          value: "2021",
          label: "2021年",
        },
        // {
        //   value: "",
        //   label: "全部",
        // },
      ],
      StatisticalCenterVlue: "2024",

      agriculturalYear: "2024",
      diggingYear: "2024",
      insuranceYear: "",
      liftingYear: "2024",
      otherYear: "2024",
      statisticalCenterYear: "2024",
      allData: {},
      isSpeak: false,
      isEditVoice: false,
      monitorDetails: {},

      fullscreenchange: null,
      intervalId: null,
    };
  },
  created() {
    const { name } = this.$store.state.userInfo;
    if (name == "华北利星行") {
      (this.header1 = false), (this.header2 = true);
    } else if (name == "广东伟信市政") {
      this.header3 = true;
    }
    //  else if (name == "中国石油" || name == "YILI_TECH") {
    //   this.header4 = true;
    // }
  },
  // mounted activated
  mounted() {
    let that = this;
    this.init();
    console.log(23232323);
    this.handleGetWeather();
    var datas = dayjs().day();
    var week = ["日", "一", "二", "三", "四", "五", "六"];
    this.dayNum = "周" + week[datas];
    this.dateText = moment().format("YYYY-MM-DD");
    this.timer = setInterval(() => {
      const today = moment(new Date()).format("HH:mm:ss");
      this.timeStr = `${today}`;
    }, 1000);

    // this.intervalId = setInterval(async () => {
    //   await this.init();
    // }, 1000 * 30);
    this.handlePolling();
    // 监听放大页面
    this.fullscreenchange = true;
    addEventListener("fullscreenchange", this.handleFullscreenchange);
    addEventListener("visibilitychange", this.handleVisibilitychange);
  },
  // deactivated
  beforeDestroy() {
    clearInterval(this.timer); //销毁定时器
    // clearInterval(this.intervalId);
    clearTimeout(this.intervalId);
    this.intervalId = null;
    this.fullscreenchange = null;
    removeEventListener("fullscreenchange", this.handleFullscreenchange);
    removeEventListener("visibilitychange", this.handleVisibilitychange);
  },

  methods: {
    async init() {
      let vehicleId = this.$route.query.vehicleId;
      getMonitorDetails({ vehicleId })
        .then((res) => {
          this.monitorDetails = res.obj;
          // this.$nextTick(() => {
          //   let list = ["monitor", "mechanical"];
          //   list.map((val) => {
          //     this.$refs[val].init();
          //   });
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePolling() {
      this.intervalId = setTimeout(async () => {
        await this.init();
        // this.$refs.ModalMap.mapChange()
        clearTimeout(this.intervalId);
        if (this.fullscreenchange) {
          this.handlePolling();
        }
      }, 1000 * 60);
    },
    handleVisibilitychange() {
      if (document.visibilityState == "hidden") {
        clearTimeout(this.intervalId);
        this.intervalId = null;
      } else {
        this.init();
        if (!this.intervalId) {
          this.handlePolling();
        }
      }
    },
    handleFullscreenchange() {
      this.init();
    },
    async handleGetWeather() {
      let cityData = await amapGetIp({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
      });
      let imgList = [
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain1.png", //小雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain2.png", //大雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/rain3.png", // 雷雨
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/cloud.png", //多云
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/snow.png", //雪
        "https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/clear.png", // 晴
      ];
      //  {city: cityData.city}
      weatherInfo({
        key: "60f85fa7d1c353b0a2ffb92083e365f0",
        city: cityData.adcode,
      }).then((res) => {
        let weatherData = res.lives[0];
        // let high = weatherData.high.split(' ')[1];
        // let low = weatherData.low.split(' ')[1];
        this.weatherArea = cityData.city;
        this.weatherText =
          weatherData.temperature + "°" + " " + weatherData.weather;
        if (weatherData.weather.indexOf("雷") > -1) {
          this.weatherImg = imgList[2];
        } else if (weatherData.weather.indexOf("大雨") > -1) {
          this.weatherImg = imgList[1];
        } else if (weatherData.weather.indexOf("雨") > -1) {
          this.weatherImg = imgList[0];
        } else if (weatherData.weather.indexOf("雪") > -1) {
          this.weatherImg = imgList[4];
        } else if (weatherData.weather.indexOf("晴") > -1) {
          this.weatherImg = imgList[5];
        } else if (weatherData.weather.indexOf("云") > -1) {
          this.weatherImg = imgList[3];
        } else {
          this.weatherImg = imgList[3];
        }
      });
    },

    /**
     * 进入全屏
     */
    requestFullscreen() {
      const fullscreenEl = this.getFullScreenEl();

      fullscreenEl.requestFullscreen();
      this.isFullscreen = true;
    },
    /**
     * 退出全屏
     */
    exitFullscreen() {
      document.exitFullscreen();
      this.isFullscreen = false;
    },
    handleClick() {
      this.SetFullscreen();
      // const isFullscreen = !this.isFullscreen;

      // if (isFullscreen) { // 进入全屏
      //   this.requestFullscreen();
      // } else {
      //   // 退出全屏
      //   this.exitFullscreen();
      // }
    },
    handleGoPath(name) {
      this.$router.push({
        path: "/" + name,
      });
    },
    handleVoice() {
      this.$refs["voice-box"].submitOk();
    },
    handlerEditVoice() {
      this.$refs["voice-box"].handlerEdit();
    },
    ...mapMutations(["SetFullscreen"]),
  },
};
</script>

<style lang="scss" scoped>
@import "./riskVideoStyle.scss";
@import "./BigScreenPublic.scss";
.bg1 {
  background-image: url("../../assets/images/bigScreen/home/titleBg.png");
}
.bg2 {
  background-image: url("../../assets/images/bigScreen/top3.png");
}
.bg3 {
  background-image: url("../../assets/images/bigScreen/top2.png");
}
.bg4 {
  background-image: url("../../assets/images/bigScreen/zgsyBg.png");
}
</style>
<style lang="scss">
.popper-style {
  width: 68px;
  input {
    color: #ffffff;
    border: 1px solid #0267cb;
    background: rgba(157, 254, 255, 0.2);
    padding-left: 0.04rem;
    padding-right: 0.04rem;
  }
  .el-input__inner {
    height: auto;
    line-height: initial;
    padding: 2px;
    padding-right: 22px;
  }
  .el-input__icon {
    height: 0.38rem;
    line-height: 0.38rem;
  }
  .el-input__suffix {
    right: 0;
  }
}
.data-center {
  .el-loading-mask {
    background-color: rgba(24, 34, 55, 0.8);
  }
}
</style>
