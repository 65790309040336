import request from '@/utils/request'

export function getWeather (params) {
    return request({
        url: 'http://wthrcdn.etouch.cn/weather_mini',
        method: 'get',
        timeout: 12000,
        params
    })
}
// 获取当前地区
export function amapGetIp (params) {
    return request({
        url: 'https://restapi.amap.com/v3/ip',
        method: 'get',
        timeout: 12000,
        params
    })
}
// 天气查询
export function weatherInfo (params) {
    return request({
        url: 'https://restapi.amap.com/v3/weather/weatherInfo',
        method: 'get',
        timeout: 12000,
        params
    })
}

