<template>
  <div class="header-box">
   <img src="@/assets/images/bigScreen/home/title-jk.png" alt="" srcset="">
   <span>{{title}}</span>
   <slot />
  </div>
</template>

<script>
export default {
   props: {
      title: {
         type: String,
         default: ''
      }
   }
}
</script>

<style lang="scss" scoped>
.header-box {
   display: flex;
   align-items: center;
   >img {
      width: 0.17rem;
      margin-right: 4px;
   }
   >span {
      font-size: 0.18rem;
      font-weight: bold;
      color: #96F5F8;
   }
}
@media only screen and (max-width: 1366px) {
   img {
      width: 0.7em;
   }
}
</style>