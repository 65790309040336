<template>
  <div class="option-box" v-infinite-scroll="load">
    <div
      class="option-box-item"
      :class="{ 'option-box-item-select': selectValue === index }"
      v-for="(item, index) in radioList"
      :key="index"
    >
      <el-input
        v-if="selectValue === index && isEdit"
        v-model="item['radioKey']"
        @input="changeInput"
        placeholder="请输入内容"
      ></el-input>
      <div v-else class="option-box-item-text">{{ item["radioKey"] }}</div>
      <div>
        <ItemRadio
          :label="item.radioKey"
          v-model="item.radioValue" :disabled="isEdit"
          size="0.14rem"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ItemRadio from "@/views/BigScreenDisplay/components/risk/ItemRadio";
import cmdWsObj, { CmdType } from "@/utils/cmdWebSocket";
import { getTtsList, saveTtsSetting, addTtsSettingNum } from "@/api/BigScreenDisplay";
export default {
  components: { ItemRadio },
  watch: {
    radioList: {
      handler(v) {
        if (this.isEdit) {
          return false;
        }
        let valueData = v.filter((val) => val.radioValue);
        if (valueData.length == 0) {
          this.selectValue = "";
          v.map((val, index) => (this.radioList[index].radioValue = ""));
        } else if (this.selectValue !== "") {
          if (valueData.length > 1) {
            let valueData2 = valueData.filter(
              (val) => val.radioValue != v[this.selectValue].radioValue
            );
            console.log(valueData2);
            this.selectValue = v.findIndex(
              (val) => val.radioValue == valueData2[0].radioValue
            );
            v.map((val, index) =>
              this.radioList[index].radioValue != v[this.selectValue].radioValue
                ? (this.radioList[index].radioValue = "")
                : ""
            );
          } else {
            this.selectValue = v.findIndex(
              (val) => val.radioValue == valueData[0].radioValue
            );
          }
        } else {
          this.selectValue = v.findIndex(
            (val) => val.radioValue == valueData[0].radioValue
          );
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      typeAllRadio: "",
      radioList: [],
      selectValue: "",
      isEdit: false,
    };
  },
  props: {
    monitorDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    getTtsList().then(res=>{
      this.radioList  =res.obj.map(val=>{
        return {
          ...val,
          radioValue: "",
          radioKey: val.ttsLabel,
        }
      });
    })
  },
  methods: {
    load() {},
    async submitOk() {
      if (!cmdWsObj.request) throw new Error("命令链路未连接");

      const { monitorDetails, selectValue, textType, downType } = this;
      if (!monitorDetails.plate) return this.$message.warning("车辆信息为空");

      if (selectValue === "") return this.$message.warning("请选择下发内容");
      let textContent = this.radioList[this.selectValue].radioValue == this.radioList[this.selectValue].radioKey ? this.radioList[this.selectValue].radioValue : this.radioList[this.selectValue].radioKey;

      const spareMessage = [
        `【${"终端TTS播报"}】`,
        `【${"通知"}】`,
        `【内容：${textContent}】`,
      ].join("\r\n");

      const cmdData = {
        groupId: monitorDetails.groupId,
        vehicleId: monitorDetails.vehicleId,
        commandType: 1000,
        param: {
          text: textContent,
          textType: 8,
          flags: ["TTS"],
          spareMessage,
        },
      };

      // TODO: 是否需要等待回复
      cmdWsObj.request.sendCmdAsync(CmdType.textDownloadSet, cmdData);
      this.handleAddTtsSettingNum({
        "vehicleId": monitorDetails.vehicleId, //车辆ID
        "groupId": monitorDetails.groupId, //车组ID
        "intervention": textContent, //干预内容
        "interventionType": 4, //干预类型: 1-对讲 2-致电监工 3-一键报警 4-TTS语音下发
        "interventionTypeName": 'TTS语音下发' //干预类型描述: 1-对讲 2-致电监工 3-一键报警 4-TTS语音下发
      });
      this.$message.info("指令已下发");
    },
    changeInput() {
      this.$forceUpdate();
    },
    handlerEdit() {
      if (this.selectValue === "") {
        this.$message.error("请选择语音！");
        return false;
      }
      this.isEdit = !this.isEdit;
      if (!this.isEdit) {
        let valueData = this.radioList.filter((val) => val.radioValue);
        saveTtsSetting({...valueData[0], ttsLabel: valueData[0].radioKey }).then(res=>{
          console.log(res);
        })
      }
      this.$emit("changeEdit", this.isEdit);
    },
    handleAddTtsSettingNum(data) {
      addTtsSettingNum(data).then(res=>{
        this.$nextTick(()=>{
          this.$emit('changeIntervene')
        })
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.option-box {
  // overflow: auto;
  overflow-x: auto;
  padding: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.14rem;
    font-weight: 300;
    color: #ffffff;
    // height: 0.46rem;
    // margin-bottom: 6px;
    background-image: url("../../../../assets/images/bigScreen/risk/no-select-bg.png");
    background-size: 100% 100%;
    padding: 4% 6%;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    // > div {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   overflow: hidden;
    // }
    &-text {
      width: calc(100% - 0.44rem);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    ::v-deep .el-input {
      max-height: 100%;
      width: 80%;
      input {
        height: 100%;
        border: none;
        color: #ffffff;
        background-color: rgba(254, 254, 255, 0.1);
      }
    }
  }
  &-item-select {
    background-image: url("../../../../assets/images/bigScreen/risk/select-bg.png");
  }
}
</style>